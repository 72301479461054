import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import VideoPlayer from './components/VideoPlayer/VideoPlayer';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import './App.scss';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MediaPlayer />} />
      </Routes>
    </Router>
  );
};

const MediaPlayer: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mediaType = params.get('media-type') || 'audio';

  return (
    <div className="App">
      {mediaType === 'video' ? (
        <VideoPlayer src="/file_example_MP4_480_1_5MG.mp4" width="600px" height="400px" />
      ) : (
        <AudioPlayer src="/launch-instructions-1.mp3" />
      )}
    </div>
  );
};

export default App;
