import React from 'react';
import './VideoPlayer.scss';

interface VideoPlayerProps {
  src: string;
  width?: string;
  height?: string;
  controls?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, width = '100%', height = 'auto', controls = true }) => {
  return (
    <div className="video-player">
      <video width={width} height={height} controls={controls}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
